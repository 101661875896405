import React, { Component } from 'react';
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Check out my published projects!
            
          </h1>
          <div id="portfolio-wrapper" className="two-col-tab">
          {
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              return(  
                <div className="columns portfolio-item">
                  <div className="item-wrap">  
                    <div className="row">
              <div className="flexslider">
                <ul className="slides">
                  {
                        item.slides && item.slides.map((item)=>{
                          return(
                        <li>
                      <img src={`${item.imgurl}`} className="item-img"/>
                        </li>
                      )
                    }
                    )
                  }
                  
                  
                </ul>
              </div> {/* div.flexslider ends */}
          </div> {/* row ends */}    
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.description}    Timeframe: {item.Timeframe}</p>
                          <p>{item.role}</p>
                          <p>-------------------------------------------------------------------------------</p>
                          <p>Responsibilities:</p>
                          <p>    -{item.responsibilities1}</p>
                          <p>    -{item.responsibilities2}</p>
                          <p>    -{item.responsibilities3}</p>
                          <p>    -{item.responsibilities4}</p>
                          <p>    -{item.responsibilities5}</p>
                          <p>-------------------------------------------------------------------------------</p>
                          <p>Click here to learn more!</p>
                        </div>
                      </div>
                    <a href={item.link} target="_blank">  
                      <div className="overlayicon">
                        <img src={item.iconImg}/>
                      </div>
                      </a>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
  </section>
        );
  }
}
