let resumeData = {
  "imagebaseurl":"images/portfolio/5.png",
  "linkedinId":" https://www.linkedin.com/in/sky-wess-9ba370200/",
  "socialLinks":
  [
      {
        "name":"Linkedin",
        "url":"https://www.linkedin.com/in/sky-wess-9ba370200/",
        "className":"fa fa-linkedin"
      },
      {
        "name":"Bitbucket",
        "url":"https://bitbucket.org/SkyWessProjects/workspace/projects/POR",
        "className":"fa fa-bitbucket"
      }
    ],
  "aboutme":"I am a technical designer from Arkansas!  I enjoy solving difficult problems, creating engaging levels, and using player feedback to improve the game!  I am always happy to learn something new.  In my free time I enjoy making hobby art including illustration, abstract painting, and 3D modeling.  I am a big fan of RPG's, action adventure games, and games where you get to explore!",
  "education":
  [
    {
      "UniversityName":"Southern Arkansas University",
      "specialization":"Computer Science: Computer Gaming and Animation Design",
      "MonthOfPassing":"August 2019 - December",
      "YearOfPassing":"2022",
      "Achievements":"3.9 GPA, Honors graduate"
    }
  ],
  "work":
  [
    {
      "CompanyName":"Murphy USA",
      "specialization":"Quality Assurance Automation Engineer I",
      "MonthOfLeaving":"January 2023 -",
      "YearOfLeaving":"Present",
      "Achievements":"Developing new automated testing tools for the QA team, including an EMV card creation/editor tool that allows the team to cover every test case for EMV cards. Communication with the development team/supervisors to determine requirements for automated testing of applications. Maintaining large codebases and updating utilities to suit current needs. Tools and technologies: C#, JavaScript, Git Bash, HTTP, Visual Studio 2019/2022, and Azure DevOps."
    },
    {
      "CompanyName":"Murphy USA",
      "specialization":"IT Intern Programmer",
      "MonthOfLeaving":"June 2022 -",
      "YearOfLeaving":"December 2022",
      "Achievements":"Developed automated testing tools for the QA team to suit the team’s current needs. Communicated with the development team and managers to determine tasks and priorities. Maintained large codebases and updated them to current technologies. Tools and technologies: C#, JavaScript, GraphQL, Git Bash, Visual Studio, and Azure DevOps."
    },
    {
      "CompanyName":"Southern Arkansas University",
      "specialization":"Data Science Research Assistant",
      "MonthOfLeaving":"Jan 2022 -",
      "YearOfLeaving":"May 2022",
      "Achievements":"Researched convolutional neural networks and ways of optimizing the resulting data. Used a large public dataset to test methods of classifying types of skin cancer images. Tested and documented optimization methods to determine which had the greatest impact. Tools and technologies: Python, Datasets, Anaconda, and Jupyter Notebook."
    },
    {
      "CompanyName":"Murphy USA",
      "specialization":"IT Intern Programmer",
      "MonthOfLeaving":"June 2021 - Jan",
      "YearOfLeaving":"2022",
      "Achievements":"Added features to existing applications and simulators at the request of the development team. Developed new frontend and backend features for existing applications. Communicated with mentors and assisted peers when they needed help with tasks. Tools and technologies: C#, C++, Restful API, Sourcetree, Visual Studio 2019, and Azure DevOps."
    }
  ],
  //"skillsDescription":"Programming Skills",
  "skills":
  [
    {
      "skillname":"C# -  4 years"
    },
    {
      "skillname":"C++ -  4 years"
    },
    {
      "skillname":"Unity Engine -  5 years"
    },
    {
      "skillname":"Unreal Engine -  1/2 years"
    },
    {
      "skillname":"Level Design -  4 years"
    },
    {
      "skillname":"Autodesk Maya -  2 years"
    },
    {
      "skillname":"Clip Studio Paint -  4 years"
    },
    {
      "skillname":"Git -  4 years"
    },
    {
      "skillname":"Azure DevOps - 2 years"
    },
    {
      "skillname":"Python -  3 years"
    },
    {
      "skillname":"HTML5 -  2 years"
    },
    {
      "skillname":"CSS -  2 years"
    },
    {
      "skillname":"JavaScript -  2 years"
    },
    {
      "skillname":"Ranerex Studio - 1 1/2 years"
    },
  ],
  "extra":
  [
    {
      "event":"2023 Jam Anonymous",
      "role":"Programming and Level/Puzzle Design (Unreal 5)",
      "date":"June 2023",
      "Achievements":""
    },
    {
      "event":"2023 LATech Game Jam",
      "role":"Gameplay/UI Programming (Unity)",
      "date":"April 2023",
      "Achievements":""
    },
    {
      "event":"2023 Global Game Jam",
      "role":"Programming and Level Design (Unreal 5)",
      "date":"February 2023",
      "Achievements":""
    },
    {
      "event":"SAU Game Development Club",
      "role":"President, Vice President, Secretary, and General Member",
      "date":"August 2019 - December 2022",
      "Achievements":""
    },
    {
      "event":"2022 Arkansas Indie Fest",
      "role":" Presented project “Light/Shade” for public playtest",
      "date":"October 2022",
      "Achievements":""
    },
    {
      "event":"2022 Little Rock Tech Fest",
      "role":" Presented projects at SAU’s Computer Science department table",
      "date":"October 2022",
      "Achievements":""
    },
    {
      "event":"2022 Fall SAU Game Jam",
      "role":"Programming, Level Design, and Shaders (Unity)",
      "date":"September 2022",
      "Achievements":""
    },
    {
      "event":"DART Annual Conference Poster Competition",
      "role":"Presented Neural Network Research Poster, Awarded 3rd Place (undergraduate division)",
      "date":"May 2022 (Little Rock, AR)",
      "Achievements":""
    },
    {
      "event":"Computer Simulation & Gaming Conference",
      "role":"Presented Light/Shade, Awarded 1st Place for Digital Games (postsecondary division)",
      "date":"April 2022 (Tulsa, OK)",
      "Achievements":""
    },
    {
      "event":"2022 Global Game Jam",
      "role":"Programming and Level Design (Unity)",
      "date":"January 2022",
      "Achievements":""
    },
    {
      "event":"Arkansas Indie Fest",
      "role":"Presented project “Snyatcher” for Public Playtest",
      "date":"October 2021 (Magnolia, AR)",
      "Achievements":""
    },
    {
      "event":"2021 SAU Fall Game Jam",
      "role":"Organization and Design (Physical Game)",
      "date":"September 2021",
      "Achievements":""
    },
    {
      "event":"Brackey's Game Jam 2021.1",
      "role":"Programming, Level Design, Sound, and Animation (Unity)",
      "date":"February 2021",
      "Achievements":""
    },
    {
      "event":"2021 Global Game Jam",
      "role":"Level Design and Programming (Unity)",
      "date":"January 2021",
      "Achievements":""
    },
    {
      "event":"2020 Global Game Jam",
      "role":"Level Design and Programming (Unity)",
      "date":"January 2020",
      "Achievements":""
    },
    {
      "event":"2019 SAU Fall Game Jam",
      "role":"Level Design and 2D Art (Unity)",
      "date":"September 2019",
      "Achievements":""
    },
  ],
  "portfolio":
  [
    {
      "name":"Snyatcher",
      "description":"3D Cat Collectathon",
      "Timeframe":"March 2023 - present",
      "role":"Lead Developer (Programming, Art, Design). Prototyped in Unity, developed in Unreal Engine 5",
      "link": "https://store.steampowered.com/app/2699530/Snyatcher/",
      "iconImg": "../images/steamlogo.png",
      "responsibilities1": "Redesigned the systems and mechanics from the ground up at the original scope.",
      "responsibilities2": "Recruited reliable team members and managed their contributions and tasks with Clickup.",
      "responsibilities3": "Designed and built the levels using Unreal's terrain and level building tools.",
      "responsibilities4": "Developed the player movement mechanics, fishing rod, dialogue system, and the cat AI running/hiding behavior.",
      "responsibilities5": "Modeled, UVed, and textured props, and refactored features of the cat model for enhanced animations.",
      "slides":
      [
        {
          "imgurl":"images/portfolio/s1.png",
        },
        {
          "imgurl":"images/portfolio/s2.png",
        },
        {
          "imgurl":"images/portfolio/s3.png",
        },
        {
          "imgurl":"images/portfolio/sr4.jpg",
        },
        {
          "imgurl":"images/portfolio/sr5.jpg",
        },
      ]
  
    },
    {
      "name":"Feed The Light",
      "description":"3D Escape Room.",
      "Timeframe":"48 Hours",
      "role":"Programmer, Technical/Puzzle Designer, and Solo Level Designer. Unreal Engine 5",
      "link": "https://stargaze-axl.itch.io/feed-the-light",
      "iconImg": "../images/itchicon.png",
      "responsibilities1": "Developed the mechanics for moving objects and detecting when they are placed in trigger areas.",
      "responsibilities2": "Designed puzzles and ensured that the player is given enough information to solve them.",
      "responsibilities3": "Designed and built the levels using Unreal's terrain and level building tools.",
      "responsibilities4": "Implemented the lighting and music into the level.",
      "responsibilities5": "Created the sequence of events the player must follow in order to progress the game.",
      "slides":
      [
        {
          "imgurl":"images/portfolio/FTL1.png",
        },
        {
          "imgurl":"images/portfolio/FTL2.png",
        },
        {
          "imgurl":"images/portfolio/FTL3.png",
        },
        {
          "imgurl":"images/portfolio/FTL4.png",
        },
        {
          "imgurl":"images/portfolio/FTL5.png",
        },
        {
          "imgurl":"images/portfolio/FTL6.jpg",
        }
      ]
  
    },
    {
      "name":"Redumption",
      "description":"3D Action Adventure Game.",
      "Timeframe":"3 Months",
      "role":"Lead Designer, Solo Level Designer, and Programmer. Unity Engine",
      "link": "https://store.steampowered.com/app/2226550/Redumption/",
      "iconImg": "../images/steamlogo.png",
      "responsibilities1": "Developed and maintained game design documents, and led design team meetings.",
      "responsibilities2": "Designed and built the level using Unity's terrain tool and assets from our art team.",
      "responsibilities3": "Programmed and designed a modular dialogue system to help us tell the game's story.",
      "responsibilities4": "Developed the cel and water shaders as well as implemented the post processing and LOD.",
      "responsibilities5": "Used public playtest feedback to improve the UX and design a more player friendly experience.",
      "slides":
      [
        {
          "imgurl":"images/portfolio/r1.jpg",
        },
        {
          "imgurl":"images/portfolio/r2.jpg",
        },
        {
          "imgurl":"images/portfolio/r3.jpg",
        }
      ]
  
    },
    {
      "name":"D.A.V.E (Destruction and Violence Everywhere)",
      "description":"VR Monster Destruction Game.",
      "Timeframe":"3 Months",
      "role":"Producer, Technical Artist, and Programmer. Unity Engine",
      "link": "https://saugamedev.itch.io/dave",
      "iconImg": "../images/itchicon.png",
      "responsibilities1": "Lead weekly stand up meetings and checked in with team leads to track progress on the project.",
      "responsibilities2": "Modeled buildings and then modeled shattered versions that could be placed in game and broken.",
      "responsibilities3": "Modeled and animated the VR monster hands the player uses in game.",
      "responsibilities4": "Created a skybox and terrain textures to be used in the main level.",
      "responsibilities5": "Assisted the programming team with technical problems and bug fixing.",
      "slides":
      [
        {
          "imgurl":"images/portfolio/d1.png",
        },
        {
          "imgurl":"images/portfolio/d2.png",
        },
        {
          "imgurl":"images/portfolio/d3.png",
        }
      ]
  
    },
    {
      "name":"Light/Shade",
      "description":"Co-op Light-based Puzzle Game. Awarded 1st Place for Digital Games at CSGC 2022.",
      "Timeframe":"48 Hours",
      "role":"Solo Programmer and Level Designer. Unity Engine",
      "link": "https://tratos.itch.io/lightshade",
      "iconImg": "../images/itchicon.png",
      "responsibilities1": "Programmed the co-op player controller and camera, allowing the game to be player single or two player.",
      "responsibilities2": "Developed mechanics to determine if a character is or is not engulfed in shadow.",
      "responsibilities3": "Implemented the post processing, VFX, and music.",
      "responsibilities4": "Programmed the user interface, including the main menu, pause menu, and level transitions.",
      "responsibilities5": "Developed levels and puzzles that require the players to work together to reach the level's goal.",
      
      "slides":
      [
        {
          "imgurl":"images/portfolio/l1.png",
        },
        {
          "imgurl":"images/portfolio/l2.png",
        },
        {
          "imgurl":"images/portfolio/l3.png",
        }
      ]
    },
    {
      "name":"Paine's Furniture Emporium",
      "description":"FPS (Furniture Punching Simulator).",
      "Timeframe":"3 Months",
      "role":"Lead Programmer, Writer, and Cutscene Animator. Unity Engine",
      "link": "https://saugamedev.itch.io/paines-furniture-emporium",
      "iconImg": "../images/itchicon.png",
      "responsibilities1": "Lead programming meetings and divided out tasks to the programming team.",
      "responsibilities2": "Programmed the player's weapons and combat, and the enemy's health UI.",
      "responsibilities3": "Developed a system that tracks what furniture the player has collected and adds it to the player's house.",
      "responsibilities4": "Wrote, sequenced, and animated the beginning and ending scenes of the game.",
      "responsibilities5": "Assisted the programming team in completing their tasks and provided constructive feedback as needed.",
      "slides":
      [
        {
          "imgurl":"images/portfolio/p1.png",
        },
        {
          "imgurl":"images/portfolio/p2.png",
        },
        {
          "imgurl":"images/portfolio/p3.png",
        }
      ]
    },
    /*{
      "name":"Proto-Snyatcher",
      "description":"Fast Paced Cat Catching Game.",
      "Timeframe":"48 Hours",
      "role":"Solo Level Designer and Programmer. Unity Engine",
      "link": "https://tratos.itch.io/snyatcher",
      "iconImg": "../images/itchicon.png",
      "responsibilities1": "Designed and built the level using Unity primitives and replaced assets as they arrived from the art team.",
      "responsibilities2": "Implemented the post-processing and lighting into the main level.",
      "responsibilities3": "Redesigned and programmed the character select UI when it was needed.",
      "responsibilities4": "Bug fixed issues to do with timers, shadows, and UI scaling.",
      "responsibilities5": "Balanced the game to be a more fun user experience.",
      "slides":
      [
        {
          "imgurl":"images/portfolio/s1.png",
        },
        {
          "imgurl":"images/portfolio/s2.png",
        },
        {
          "imgurl":"images/portfolio/s3.png",
        }
      ]
    }*/
  ],
  "uportfolio":
  [
    {
      "name":"The Undergrowth",
      "description":"3D Stealth Survival Game",
      "Timeframe":"48 Hours",
      "role":"Programmer and Level Designer. Unreal Engine 5",
      "imgurl":"images/portfolio/TU2.jpg"
    },
    {
      "name":"Level design Practice 1",
      "description":"A small isometric 3D swamp.",
      "Timeframe":"1 Week",
      "role":"Level Designer. Unity Engine",
      "imgurl":"images/testimonials-bg.jpg"
    },
    {
      "name":"The Possessed  - 2021 Fall SAU Game Jam",
      "description":"A card game where you use card abilities to find out who is possessed.",
      "Timeframe":"36 Hours",
      "role":"Card Illustrator and Mechanics Designer.",
      "imgurl":"images/portfolio/u2.png"
    },
    {
      "name":"A Guide to Getting Famous - Game Development Final",
      "description":"Help these 3 content creators make it big.",
      "Timeframe":"2 Weeks",
      "role":"Lead Programmer, Character Sprite Artist, and Level Designer. Unity Engine",
      "imgurl":"images/portfolio/u3.png"
    },
    {
      "name":"Level design Practice 2 - Game Development Midterm",
      "description":"A small floating island with a hidden subplot.",
      "Timeframe":"1 Week",
      "role":"Level Designer. Unity Engine",
      "imgurl":"images/portfolio/u4.png"
    },
    {
      "name":"Tamagotchi Clone - Fundamentals of Game Programming 1",
      "description":"A critter care game.",
      "Timeframe":"1 Week",
      "role":"Programmer and Pixel Artist. Unity Engine",
      "imgurl":"images/portfolio/u5.png"
    },
    {
      "name":"Just a Game Without a Name - Brackey's Game Jam 2021.1",
      "description":"A little astronaut experiences several genre shifts.",
      "Timeframe":"1 Week",
      "role":"Programmer and Level Designer. Unity Engine",
      "imgurl":"images/portfolio/u7.png"
    },
    {
      "name":"Re-pair Game (RPG) - Global Game Jam 2020",
      "description":"Repairing a game from the inside.",
      "Timeframe":"48 Hours",
      "role":"Level Designer and Programmer. Unity Engine",
      "imgurl":"images/portfolio/u8.png"
    },
    {
      "name":"Never Noir - 2019 Fall SAU Game Jam",
      "description":"A detective investigates a secret research facility.",
      "Timeframe":"2 Months",
      "role":"Level Designer and Programmer. Unity Engine",
      "imgurl":"images/portfolio/u9.png"
    },
  ],
  "aportfolio":
  [
    {
      "imgurl":"images/portfolio/8.png",
    },
    {
      "imgurl":"images/portfolio/9.png",
    },
    {
      "imgurl":"images/portfolio/10.jpg",
    },
    {
      "imgurl":"images/portfolio/3.png",
    },
    {
      "imgurl":"images/portfolio/1.png",
    },
    {
      "imgurl":"images/portfolio/2.png",
    },
    {
      "imgurl":"images/portfolio/4.png",
    },
    {
      "imgurl":"images/portfolio/5.png",
    },
    {
      "imgurl":"images/portfolio/6.png",
    },
    {
      "imgurl":"images/portfolio/7.jpg",
    },
  ],
  "testimonials":
  [
  ]
}

export default resumeData
