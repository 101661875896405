import React, { Component } from 'react';
export default class Testimonials extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="testimonials">
          <div className="row">
          </div> {/* row ends */}
      </section>
        );
  }
}
