import React, { Component } from 'react';
import About from './components/about/about';
//import Resume from './components/resume/resume';
//import APortfolio from './components/aportfolio/aportfolio';
import UPortfolio from './components/uportfolio/uportfolio';
import Portfolio from './components/portfolio/portfolio';
import Testimonials from  './components/testimonials/testimonials';
import ContactUs from './components/contactus/contactus';
import Footer from './components/footer/footer';
import resumeData from './resumeData';
class App extends Component {
  render() {
    return (
      <div className="App">
        <About resumeData={resumeData}/>
        <Testimonials resumeData={resumeData}/>
        <Portfolio resumeData={resumeData}/>
        <UPortfolio resumeData={resumeData}/>
        <Testimonials resumeData={resumeData}/>
        <ContactUs resumeData={resumeData}/>
        <Footer resumeData={resumeData}/>
      </div>
    );
  }
}

export default App;
