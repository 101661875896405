import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
      <React.Fragment>
      {/*generated code*/}
         <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
               <li><a className="smoothscroll" href="#about">About</a></li>
               <li><a className="smoothscroll" href="#portfolio">Projects</a></li>
               <li><a className="smoothscroll" href="#contact">Contact</a></li>
               <li><a target="_blank" href="https://docs.google.com/document/d/1hyoZ0Z_nsJgdCetoK6CUKYJabriqHo-SenYXezTqzXA/edit?usp=sharing">Full Resume</a></li>
            </ul>
         </nav>
      </React.Fragment>
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="images/liz.png" alt="" />

            </div>

            <div className="nine columns main-col">
              <h1>My name is Sky Wess!</h1>
               <h2>About Me</h2>
               <p>
               {
                 resumeData.aboutme
               }
               </p>
            </div>
         </div>
      </section>
    );
  }
}
